import React, { useEffect } from 'react'
import { getCourses } from 'reducks/courses/selectors'
import { State } from 'index'
import { useDispatch, useSelector } from 'react-redux'
import { PageTitle, SecondaryButton } from 'components/UIkit'
import { selectCourse } from 'reducks/users/operations'
import {
    getDoneTestsAmount,
    getDoneTestsRate,
    getReadCurriculumAmount,
    getReadCurriculumRate,
} from 'reducks/users/selectors'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'
import { fetchCourses } from 'reducks/courses/operations'

const Courses = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const courses = getCourses(selector)
    const pageTitle = getPageTitle(selector)
    const doneTestsAmount = getDoneTestsAmount(selector)
    const doneTestsRate = getDoneTestsRate(selector)
    const readCurriculumAmount = getReadCurriculumAmount(selector)
    const readCurriculumRate = getReadCurriculumRate(selector)

    useEffect(() => {
        const title = 'コース選択'
        dispatch(setTitleAction(title))

        if (courses.length === 0) {
            dispatch(fetchCourses())
        }
    }, [])

    return (
        <section className='c-section'>
            <div className='c-section-wrapin'>
                <div className='u-display-none-over-md'>
                    <PageTitle title={pageTitle} />
                </div>
                <div>
                    {courses.map((course) => (
                        <div className='p-grid__list-items--higher' key={course.id}>
                            <div className='p-media__square eyecatch'>
                                <img src={course.icon_path} alt={'スワクラブ'} />
                            </div>
                            <div className='body'>
                                <h3>{course.name}コース</h3>
                                <p>
                                    カリキュラム達成数&nbsp;
                                    {course.id === 'swift' ? readCurriculumAmount.swift : readCurriculumAmount.website}
                                    &nbsp;(
                                    {course.id === 'swift' ? readCurriculumRate.swift : readCurriculumRate.website}%)
                                </p>
                                <p>
                                    課題達成数&nbsp;
                                    {course.id === 'swift' ? doneTestsAmount.swift : doneTestsAmount.website}&nbsp;(
                                    {course.id === 'swift' ? doneTestsRate.swift : doneTestsRate.website}%)
                                </p>
                                <p>{course.description}</p>
                            </div>
                            <div className='buttons'>
                                <SecondaryButton
                                    label={'受講を始める'}
                                    onClick={() => {
                                        dispatch(selectCourse(course.id))
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Courses
