import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { signOut } from 'reducks/users/operations'
import { pushTransition } from 'reducks/router/operation'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { getUserId, getUserRole } from 'reducks/users/selectors'
import { getCourseId } from 'reducks/users/selectors'

const HeaderMenu = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const userRole = getUserRole(selector)
    const userId = getUserId(selector)
    const course = getCourseId(selector)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const selectMenu = (path: string) => {
        dispatch(pushTransition(path))
        handleClose()
    }

    return (
        <>
            <IconButton
                aria-label='Menu Items'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleClick}
                color='inherit'
            >
                <MenuIcon />
            </IconButton>
            <Menu id='menu-appbar' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {(userRole === 'teacher' || userRole === 'administrator') && (
                    <MenuItem onClick={() => selectMenu('/pg-admin')}>管理者メニュー</MenuItem>
                )}
                <MenuItem onClick={() => selectMenu('/courses')}>コース選択</MenuItem>
                <MenuItem onClick={() => selectMenu(`/${course}`)}>カリキュラム</MenuItem>
                <MenuItem onClick={() => selectMenu(course === '' ? '/tests' : `/${course}/tests`)}>課題</MenuItem>
                <MenuItem onClick={() => selectMenu('/user/profile/' + userId)}>マイページ</MenuItem>
                <MenuItem onClick={() => dispatch(signOut())}>ログアウト</MenuItem>
            </Menu>
        </>
    )
}

export default HeaderMenu
