import React, { useCallback, useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { db } from 'firebase/index'
import NoProfile from 'assets/img/src/no-profile.png'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../index'
import {
    GreyButton,
    PrimaryButton,
    SecondaryButton,
    TextDetail,
    TextWithCopyButton,
    ModalShare,
} from 'components/UIkit'
import { getIsSubscriber, getLoyaltyPoints, getUserId, getUserRole } from 'reducks/users/selectors'
import { pushTransition } from 'reducks/router/operation'
import { getCourseId } from 'reducks/users/selectors'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'
import { unsubscribeMembership } from 'reducks/payments/operations'

import { makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core'
import {
    getDoneTestsAmount,
    getDoneTestsRate,
    getReadCurriculumAmount,
    getReadCurriculumRate,
} from 'reducks/users/selectors'
import { openTextModalAction, closeModalAction } from 'reducks/modal/action'
import { dateToString } from 'functions/commonFunc'

const useStyles = makeStyles({
    tableRow: {
        '& > th,td': {
            padding: 8,
        },
    },
    nowrap: {
        whiteSpace: 'nowrap',
    },
    shareButton: {
        display: 'block',
        margin: '15px auto',
    },
})

const UserProfile = () => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const selector = useSelector((state: State) => state)
    const pageTitle = getPageTitle(selector)
    const uid: string = window.location.pathname.split('/profile/')[1]
    const myUid: string = getUserId(selector)
    const isSubscriber: boolean = getIsSubscriber(selector)
    const isMyPage: boolean = uid === myUid
    const role = getUserRole(selector)
    const status = isSubscriber ? (role === 'supported' ? 'サポート会員' : '有料会員') : '無料会員'

    const invitationLpUrl = 'https://playground.style/official/learning/?id=' + myUid
    const invitationRegistrationUrl = 'https://pg-learning.net/registration/' + myUid

    const doneTestsAmount = getDoneTestsAmount(selector)
    const doneTestsRate = getDoneTestsRate(selector)
    const readCurriculumAmount = getReadCurriculumAmount(selector)
    const readCurriculumRate = getReadCurriculumRate(selector)
    const loyaltyPoints = getLoyaltyPoints(selector)
    const courseId = getCourseId(selector)

    const [email, setEmail] = useState(''),
        [iconPath, setIconPath] = useState(''),
        [introduction, setIntroduction] = useState(''),
        [username, setUsername] = useState(''),
        [isRegistered, setIsRegistered] = useState(false),
        [disabled, setDisabled] = useState(false),
        [timestampYear, setTimestampYear] = useState<number>(0),
        [timestampMonth, setTimestampMonth] = useState<number>(0),
        [timestampDay, setTimestampDay] = useState<number>(0)

    const pushTransitionEditProfilePage = useCallback(() => {
        dispatch(pushTransition('/user/edit'))
    }, [])

    const pushTransitionEditBankPage = useCallback(() => {
        dispatch(pushTransition('/bank'))
    }, [])

    const pushTransitionTopPage = useCallback(() => {
        dispatch(pushTransition('/' + getCourseId(selector)))
    }, [])

    const unsubscribe = useCallback(async () => {
        dispatch(unsubscribeMembership())
    }, [])

    useEffect(() => {
        // Set Title
        const title = 'ユーザーのプロフィール'
        dispatch(setTitleAction(title))

        // Fetch user profile
        db.collection('users')
            .doc(uid)
            .get()
            .then((snapshot) => {
                const profile = snapshot.data()
                if (profile) {
                    setEmail(profile.email)
                    setIconPath(profile.icon_path === '' ? NoProfile : profile.icon_path)
                    setIntroduction(profile.introduction)
                    setUsername(profile.username)
                    if (profile.last_daily_shared_at) {
                        const lastDailyShared = new Date(profile.last_daily_shared_at.seconds * 1000)
                        setTimestampYear(lastDailyShared.getFullYear())
                        setTimestampMonth(lastDailyShared.getMonth())
                        setTimestampDay(lastDailyShared.getDate())
                    }
                }
            })
    }, [loyaltyPoints])

    // Verify that the user has already registered bank data or not
    useEffect(() => {
        db.collection('users')
            .doc(uid)
            .collection('banks')
            .doc(uid)
            .get()
            .then((snapshot) => {
                if (snapshot.data()) {
                    setIsRegistered(true)
                }
            })
    }, [setIsRegistered])

    // 追加要素
    // 現在の日付
    const today = new Date()
    const todayDate = dateToString(today)

    // 最後にシェアした日付
    const timestampDate = new Date(timestampYear, timestampMonth, timestampDay)
    const timestampFormat = dateToString(timestampDate)

    const text =
        courseId === 'swift'
            ? `${todayDate}のSwiftコース
カリキュラム達成数 ${readCurriculumAmount.swift} (${readCurriculumRate.swift}%)
課題達成数 ${doneTestsAmount.swift} (${doneTestsRate.swift}%)`
            : `${todayDate}のLP制作コース
カリキュラム達成数 ${readCurriculumAmount.website} (${readCurriculumRate.website}%)
課題達成数 ${doneTestsAmount.website} (${doneTestsRate.website}%)`

    // シェアボタンonclick挙動
    const shareButtonAction = () => {
        // モーダル中身
        const body =
            courseId === 'swift'
                ? `${todayDate}のSwiftコース<br />カリキュラム達成数 ${readCurriculumAmount.swift} (${readCurriculumRate.swift}%)<br />課題達成数 ${doneTestsAmount.swift} (${doneTestsRate.swift}%)`
                : `${todayDate}のLP制作コース<br />カリキュラム達成数 ${readCurriculumAmount.website} (${readCurriculumRate.website}%)<br />課題達成数 ${doneTestsAmount.website} (${doneTestsRate.website}%)`

        const title = courseId === 'swift' ? 'Swiftコース達成数・達成率' : 'LP制作コース達成数・達成率'
        const func = () => null
        dispatch(openTextModalAction(body, func, title)) // モーダル開く
    }

    // シェアボタンdisabledタイミング
    useEffect(() => {
        setDisabled(true)
        if (timestampFormat === todayDate) {
            // 最後にシェアした日付 === 現在の日付
            dispatch(closeModalAction()) // モーダルを閉じる
        } else {
            setDisabled(false) // シェアボタンアクティブ化
        }
    }, [timestampFormat])

    return (
        <section>
            <div className='c-section__container'>
                <h2 className='u-display-none-over-md'>{pageTitle}</h2>
                <div className='p-media__profile'>
                    <img alt='profile icon' id='icon' src={iconPath} />
                </div>
                <h2>{username}</h2>
                <div className='module-spacer--medium' />
                <TextDetail label={'自己紹介'} value={introduction} />
                {isMyPage && (
                    <>
                        <TextDetail label={'Email'} value={email} />
                        <TextDetail label={'会員ステータス'} value={status} />
                        <TextDetail label={'シェアポイント'} value={String(loyaltyPoints.toLocaleString())} />

                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell />
                                        <TableCell className={classes.nowrap} align='center'>
                                            カリキュラム
                                        </TableCell>
                                        <TableCell className={classes.nowrap} align='center'>
                                            課題
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell component='th' scope='row'>
                                            Swift
                                        </TableCell>
                                        <TableCell className={classes.nowrap} align='center'>
                                            {readCurriculumAmount.swift}
                                            <br />({readCurriculumRate.swift}%)
                                        </TableCell>
                                        <TableCell className={classes.nowrap} align='center'>
                                            {doneTestsAmount.swift}
                                            <br />({doneTestsRate.swift}%)
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell component='th' scope='row'>
                                            LP制作
                                        </TableCell>
                                        <TableCell className={classes.nowrap} align='center'>
                                            {readCurriculumAmount.website}
                                            <br />({readCurriculumRate.website}%)
                                        </TableCell>
                                        <TableCell className={classes.nowrap} align='center'>
                                            {doneTestsAmount.website}
                                            <br />({doneTestsRate.website}%)
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <ModalShare text={text} />
                            <Button
                                className={classes.shareButton}
                                variant='contained'
                                disabled={disabled}
                                onClick={() => {
                                    shareButtonAction()
                                }}
                            >
                                SNSでシェアする
                            </Button>
                        </TableContainer>
                        <div className='module-spacer--medium' />
                        <section className='center'>
                            {isRegistered ? (
                                <>
                                    <TextWithCopyButton
                                        isEditable={false}
                                        label={'招待コード付き紹介ページURL'}
                                        text={invitationLpUrl}
                                    />
                                    <TextWithCopyButton
                                        isEditable={false}
                                        label={'招待コード付き登録ページURL'}
                                        text={invitationRegistrationUrl}
                                    />
                                </>
                            ) : (
                                <PrimaryButton
                                    label={'招待コードを発行する'}
                                    onClick={() => {
                                        if (isSubscriber) {
                                            alert(
                                                '招待コードの発行には、キャッシュバックの受け取りのため口座情報の登録が必要です。'
                                            )
                                            dispatch(pushTransition('/bank/edit'))
                                        } else {
                                            alert('招待コードの発行には、有料会員登録が必要です。')
                                            dispatch(pushTransition('/checkout?email=' + email))
                                        }
                                    }}
                                />
                            )}
                            <SecondaryButton label={'プロフィールの編集'} onClick={pushTransitionEditProfilePage} />
                            <SecondaryButton label={'お振込先情報の確認'} onClick={pushTransitionEditBankPage} />
                            <PrimaryButton label={'退会する'} onClick={unsubscribe} />
                        </section>
                    </>
                )}
                <section className='center'>
                    <GreyButton label={'トップ画面に戻る'} onClick={pushTransitionTopPage} />
                </section>
            </div>
        </section>
    )
}

export default UserProfile
