import React, { useEffect } from 'react'
import { PageTitle } from 'components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { pushTransition } from 'reducks/router/operation'
import { getCourseId } from 'reducks/users/selectors'
import { State } from '../index'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const AdminMenu = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const course = getCourseId(selector)
    const pageTitle = getPageTitle(selector)

    useEffect(() => {
        const title = '管理者メニュー'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section className='c-section'>
            <div className='c-section-wrapin'>
                <div className='module-spacer--medium' />
                <div className='u-display-none-over-md'>
                    <PageTitle title={pageTitle} />
                </div>
                <div className='module-spacer--medium' />
                <ul className='p-grid__menu'>
                    <li>
                        <a onClick={() => dispatch(pushTransition('/pg-admin/chapters'))} role='button'>
                            チャプター管理
                        </a>
                    </li>
                    <li>
                        <a onClick={() => dispatch(pushTransition('/courses'))} role='button'>
                            コース選択
                        </a>
                    </li>
                    <li>
                        <a onClick={() => dispatch(pushTransition('/notifications'))} role='button'>
                            お知らせ管理
                        </a>
                    </li>
                    <li>
                        <a onClick={() => dispatch(pushTransition(`/${course}`))} role='button'>
                            カリキュラム
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={() => dispatch(pushTransition(course === '' ? '/tests' : `/${course}/tests`))}
                            role='button'
                        >
                            課題
                        </a>
                    </li>
                    <li>
                        <a onClick={() => dispatch(pushTransition('/pg-admin/users'))} role='button'>
                            ユーザー管理
                        </a>
                    </li>
                    <li>
                        <a onClick={() => dispatch(pushTransition('/pg-admin/supports'))} role='button'>
                            サポート管理
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default AdminMenu
