import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { State } from 'index'
import Logo from 'assets/img/icons/logo.png'
import { getUserId } from 'reducks/users/selectors'
import { getPageTitle } from 'reducks/page/selectors'

const HeadTags = () => {
    const selector = useSelector((state: State) => state)
    const path: string = selector.router.location.pathname
    const baseUrl: string = 'https://pg-learning.net'
    const uid: string = getUserId(selector)
    const pageTitle = getPageTitle(selector)

    let title: string = 'Playground｜' + pageTitle
    let image = Logo

    return (
        <Helmet
            title={title}
            meta={[
                { property: 'og:type', content: 'website' },
                { property: 'og:url', content: baseUrl + path },
                { property: 'og:image', content: image },
                { property: 'og:title', content: title },
                {
                    property: 'og:description',
                    content:
                        'PlaygroundはオンラインでSwiftを学べるプログラミング学習サービスです。株式会社Playgroundが運営しています。',
                },
                { name: 'twitter:card', content: 'summary_large_image' },
                { name: 'twitter:site', content: baseUrl + path },
                { name: 'twitter:title', content: title },
                {
                    name: 'twitter:description',
                    content:
                        'PlaygroundはオンラインでSwiftを学べるプログラミング学習サービスです。株式会社Playgroundが運営しています。',
                },
                { name: 'twitter:image', content: image },
                { name: 'twitter:site', content: '@TechPlaygr0und' },
            ]}
        >
            {(path === '/thankyou' || path === '/supportThankyou') && (
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({'salesOrder':'${uid}'});
                    `}
                </script>
            )}
            <script>
                {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-NP7TSFG');
                `}
            </script>
            <script>
                {`
                    !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
                    },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
                    a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
                    // Insert Twitter Pixel ID and Standard Event data below
                    twq('init','o3rr0');
                    twq('track','PageView');
                `}
            </script>
            {(path === '/thankyou' || path === '/supportThankyou') && <script>{` var msmaf_m_v = ${uid} `}</script>}
        </Helmet>
    )
}

export default HeadTags
