import React from 'react'
import { useDispatch } from 'react-redux'
import { pushTransition } from 'reducks/router/operation'

const Footer = () => {
    const dispatch = useDispatch()
    return (
        <footer className='footer'>
            <div className='main c-section'>
                <div className='main-inner'>
                    <section className='foot'>
                        <ul>
                            <a
                                className='u-text__link'
                                href='https://playground.style/'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <li>運営会社</li>
                            </a>
                            <a
                                className='u-text__link u-text__cursor-pointer'
                                onClick={() => dispatch(pushTransition('/' + 'faq'))}
                                role='button'
                            >
                                <li>FAQ</li>
                            </a>
                            <a
                                className='u-text__link'
                                href='https://playground.style/terms-of-service/'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <li>利用規約</li>
                            </a>
                            <a
                                className='u-text__link'
                                href='https://playground.style/privacy-policy/'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <li>プライバシーポリシー</li>
                            </a>
                            <a
                                className='u-text__link'
                                href='https://playground.style/commercial-transaction-act/'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <li>特定商取引法に基づく表示</li>
                            </a>
                            <a
                                className='u-text__link'
                                href='https://forms.gle/PnL8TWxCnVssQXAo8'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <li>ご意見・ご要望フォーム</li>
                            </a>
                            <a className='u-text__link'>
                                <li>Copyright &copy; 2019 Playground inc.</li>
                            </a>
                        </ul>
                    </section>
                </div>
            </div>
        </footer>
    )
}

export default Footer
