export const firebaseConfig = ((nodeEnv: string, projectId: string | undefined) => {
    if (nodeEnv === 'production' && projectId === 'pg-school-e2660') {
        return {
            apiKey: 'AIzaSyCnFGX79gxkFShsUzBcBV0qUryEkYr_fro',
            authDomain: 'pg-school-e2660.firebaseapp.com',
            databaseURL: 'https://pg-school-e2660.firebaseio.com',
            projectId: 'pg-school-e2660',
            storageBucket: 'pg-school-e2660.appspot.com',
            messagingSenderId: '47743725109',
            appId: '1:47743725109:web:390de94634d82010448b7c',
            measurementId: 'G-87VY926ZEF',
        }
    } else {
        return {
            apiKey: 'AIzaSyCaWfkwURXgsqdDiz2z5YRq7VD8AvLb84o',
            authDomain: 'pg-school-dev-4ce92.firebaseapp.com',
            databaseURL: 'https://pg-school-dev-4ce92.firebaseio.com',
            projectId: 'pg-school-dev-4ce92',
            storageBucket: 'pg-school-dev-4ce92.appspot.com',
            messagingSenderId: '66816333477',
            appId: '1:66816333477:web:8ad52d44fb5e978e2e1f4c',
            measurementId: 'G-S7PXNL6CML',
        }
    }
})(process.env.NODE_ENV, process.env.REACT_APP_PROJECT_ID)
