import React, { useMemo } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ChapterItem } from 'reducks/chapters/types'
import { getCurriculumItems } from 'reducks/curriculum/selectors'
import { useSelector } from 'react-redux'
import { State } from 'index'
import { CurriculumItem } from 'reducks/curriculum/types'
import { ActionButtons } from 'components/Curriculum'
import { CurriculumListItem } from 'components/Curriculum'
import { Typography } from '@material-ui/core'
import { getIsSubscriber, getUserRole, getReadCurriculum, getDoneTests } from 'reducks/users/selectors'
import { getTestItems } from '../../reducks/tests/selectors'
import { TestItem } from '../../reducks/tests/types'

interface ChapterProps {
    chapter: ChapterItem
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: '16px',
            width: '100%',
        },
        heading: {
            fontColor: '#444',
            fontSize: theme.typography.pxToRem(18),
            fontWeight: theme.typography.fontWeightBold,
        },
        progressRate: {
            fontColor: '#444',
            fontSize: theme.typography.pxToRem(18),
            fontWeight: theme.typography.fontWeightBold,
            margin: 'auto 0 auto auto',
        },
    })
)

const ExpansionList = (props: ChapterProps) => {
    const classes = useStyles()
    const selector = useSelector((state: State) => state)
    const type = props.chapter.order.length === 4 ? 'curriculum' : 'test'
    const items: (CurriculumItem | TestItem)[] =
        type === 'curriculum' ? getCurriculumItems(selector) : getTestItems(selector)
    const doneItems = type === 'curriculum' ? getReadCurriculum(selector) : getDoneTests(selector)

    const userRole = getUserRole(selector)
    const isSubscriber = getIsSubscriber(selector)
    const isChapterPage = selector.router.location.pathname.includes('/chapters')

    const chapterId = props.chapter.chapter_id
    const isUncategorized = chapterId === ''
    const isManager = userRole === 'teacher' || userRole === 'administrator'

    // チャプター毎の量
    const chapterAmount = useMemo(() => {
        let count = 0
        items.forEach((item: CurriculumItem | TestItem) => {
            if (item.isPublished && item.chapter_id === props.chapter.chapter_id) {
                count++
            }
        })
        return count
    }, [items])

    // 受講済の数
    const progressRate = useMemo(() => {
        let count = 0
        const keys = Object.keys(doneItems)
        const values = Object.values(doneItems)

        items.forEach((item: CurriculumItem | TestItem) => {
            if (item.isPublished && item.chapter_id === props.chapter.chapter_id) {
                const index = keys.findIndex((key) => key === item.id)
                if (index >= 0 && values[index]) {
                    count++
                }
            }
        })
        return count
    }, [doneItems, items])

    return (
        <div className={classes.root}>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                >
                    <Typography className={classes.heading}>{props.chapter.chapter_name}</Typography>
                    <Typography className={classes.progressRate}>
                        達成数（{progressRate}/{chapterAmount}）
                    </Typography>
                    {/* Show buttons to edit or delete */}
                    {isManager && !isUncategorized && isChapterPage && <ActionButtons chapterId={chapterId} />}
                </ExpansionPanelSummary>

                {items.length > 0 &&
                    items.map((item: CurriculumItem | TestItem) => {
                        // If the curriculum is not published
                        if ((userRole === 'student' || userRole === 'supported') && !item.isPublished) {
                            return <></>

                            // If the curriculum does not belong to this chapter
                        } else if (item.chapter_id !== props.chapter.chapter_id) {
                            return <></>

                            // If the curriculum is not published for free and the user does not subscribe
                        } else if (!isSubscriber && !item.is_free) {
                            return (
                                <CurriculumListItem
                                    eyecatch_path={item.eyecatch_path}
                                    hasDone={item.hasDone}
                                    id={item.id}
                                    key={item.id}
                                    isFree={item.is_free}
                                    isSubscriber={isSubscriber}
                                    title={item.title}
                                    type={type}
                                    updated_at={item.updated_at}
                                />
                            )

                            // If the curriculum belongs to this chapter
                        } else {
                            return (
                                <CurriculumListItem
                                    eyecatch_path={item.eyecatch_path}
                                    hasDone={item.hasDone}
                                    id={item.id}
                                    key={item.id}
                                    isFree={item.is_free}
                                    isSubscriber={isSubscriber}
                                    title={item.title}
                                    type={type}
                                    updated_at={item.updated_at}
                                />
                            )
                        }
                    })}
            </ExpansionPanel>
        </div>
    )
}

export default ExpansionList
